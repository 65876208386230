import React, { useEffect, lazy } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import "pure-react-carousel/dist/react-carousel.es.css";

import Login from "./views/Auth/Login";
import Register from "./views/Auth/Register";
import OurTeam from "./views/OurTeam/index";
import Leaders from "./views/Leaders";
import UserProfile from "./views/Users";
import ChangePassword from "./views/Users/ChangePassword";
import Meetings from "./views/Users/Meetings";
import ForgotPassword from "./views/Auth/ForgotPassword";
import PaymentHistory from "./views/Users/PaymentHistory";
import RequestMeeting from "./views/Leaders/LeaderProfile";
import Payment from "./views/Users/Payment";
import Confirmation from "./views/Leaders/Confirmation";
import Accept from "./views/Meetings/Accept";
import RejectWithNote from "./views/Meetings/Reject_with_note";
import CancelWithNote from "./views/Meetings/Cancel_with_note";
import Review from "./views/Meetings/Review";
import Wishes from "./views/Wishes";
import TermsConditions from "./views/Terms&Conditions/Terms&Conditions";

const BecomeLeader = lazy(() => import("./views/BecomeLeader"));
const BecomeLearner = lazy(() => import("./views/BecomeLearner"));
const Dashboard = lazy(() => import("./views/Dashboard"));
const Introduction = lazy(() => import("./views/Introduction"));
const ConversationQuestions = lazy(() => import("./views/ConversationQuestions"));
const MyanmarEarthquake = lazy(() => import("./views/MyanmarEarthquake"));

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route
          path="/"
          element={
            <React.Suspense fallback={<>...loading...</>}>
              <Dashboard />
            </React.Suspense>
          }
        />
        <Route
          path="/introduction"
          element={
            <React.Suspense fallback={<>...loading...</>}>
              <Introduction />
            </React.Suspense>
          }
        />
        <Route
          path="/conversation-questions"
          element={
            <React.Suspense fallback={<>...loading...</>}>
              <ConversationQuestions />
            </React.Suspense>
          }
        />
        <Route
          path="/Myanmar-earthquake-relief-efforts"
          element={
            <React.Suspense fallback={<>...loading...</>}>
              <MyanmarEarthquake />
            </React.Suspense>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />

        <Route path="/our-team" element={<OurTeam />} />
        <Route path="/meetings/accept/:id" element={<Accept />} />
        <Route path="/meetings/reject_with_note/:id" element={<RejectWithNote />} />
        <Route path="/meetings/cancel_with_note/:id" element={<CancelWithNote />} />
        <Route path="/meetings/review/:id" element={<Review />} />
        <Route
          path="/become-a-leader"
          element={
            <React.Suspense fallback={<>...</>}>
              <BecomeLeader />
            </React.Suspense>
          }
        />
        <Route
          path="/become-a-learner"
          element={
            <React.Suspense fallback={<>...</>}>
              <BecomeLearner />
            </React.Suspense>
          }
        />
        <Route path="/schedule-meeting" element={<Leaders />} />
        <Route path="/leaders/profile/:id" element={<RequestMeeting />} />
        <Route path="/leaders/confirmation/:id/" element={<Confirmation />} />
        <Route path="/user/profile" element={<UserProfile inputTab="profile"/>} />
        <Route path="/user/leader-profile" element={<UserProfile inputTab="leaderProfile"/>} />
        <Route path="/user/changePassword" element={<ChangePassword />} />
        <Route path="/user/meetings" element={<Meetings />} />
        <Route path="/user/payment" element={<Payment />} />
        <Route path="/user/paymentHistory" element={<PaymentHistory />} />
        <Route path="/make-a-wish" element={<Wishes />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
      </Routes>
    </Router>
  );
}

export default App;
